<template>
  <div class="block-flyout-departments-mobile">
    <UtilRouteLink
      v-if="flyoutDataSide?.link?.cached_url"
      :link="flyoutDataSide.link.cached_url"
      class="block-flyout-departments-mobile__title"
    >
      {{ flyoutDataSide.title }}
      <IonIcon icon-name="chevron-right" />
    </UtilRouteLink>
    <div
      v-else
      class="block-flyout-departments-mobile__title"
    >
      {{ flyoutDataMain.title }}
    </div>
    <div class="block-flyout-departments-mobile__links">
      <div
        v-for="link in data.links"
        :key="link._uid"
      >
        <div
          v-if="link.sub_links.length === 0"
          class="block-flyout-departments-mobile__link-wrapper"
        >
          <AtomLink
            class="block-flyout-departments-mobile__link"
            :link="link.link.cached_url"
            :text="link.label"
          />
          <div
            v-if="link.label_image?.filename"
            class="block-flyout-departments-mobile__link-image-wrapper"
          >
            <AtomImage
              :image="link.label_image"
              class="block-flyout-departments-mobile__link-image"
              provider="storyblok"
            />
          </div>
        </div>
        <div
          v-else-if="link.sub_links.length > 0"
          class="block-flyout-departments-mobile__link-wrapper"
          @click="setFlyoutData(link)"
          @keypress="setFlyoutData(link)"
        >
          <p class="block-flyout-departments-mobile__link">
            {{ link.label }}
          </p>
          <div
            v-if="link.label_image?.filename"
            class="block-flyout-departments-mobile__link-image-wrapper"
          >
            <AtomImage
              :image="link.label_image"
              class="block-flyout-departments-mobile__link-image"
              provider="storyblok"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="block-flyout-departments-mobile__gradient" />
    <div
      class="block-flyout-departments-mobile__navigation-back"
      @click="setPreviousFlyoutData()"
      @keypress="setPreviousFlyoutData()"
    >
      <IonIcon icon-name="chevron-left" />
      <p>{{ useStoryblokTranslation('general', 'back') }}</p>
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    backgroundColorMain: {
        type: String,
        default: '',
    },
});

const flyoutStore = useFlyoutStore();
const flyoutDataMain = computed(() => flyoutStore.getFlyoutDataMain);
const flyoutDataSide = computed(() => flyoutStore.getFlyoutDataSide);

const setFlyoutData = (data) => {
    // prepare data for flyoutstore
    const flyoutData = {
        title: data.label,
        links: data.sub_links,
        content_side: data?.content_side[0],
    };

    // set flyout data main
    flyoutStore.setFlyoutDataMain(flyoutData);

    // set flyout data side
    if (data?.content_side?.length > 0) {
        flyoutStore.setFlyoutDataSide(data?.content_side[0]);
    }
};

const setPreviousFlyoutData = () => {
    flyoutStore.setFlyoutDataMain();

    if (flyoutDataMain.value?.content_main) {
        flyoutStore.setFlyoutColor();
    }
};
</script>

<style lang="scss" scoped>
.block-flyout-departments-mobile {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    color: var(--c-white);
    row-gap: 40px;
}

.block-flyout-departments-mobile__gradient {
    position: absolute;
    z-index: 1;
    bottom: 114px;
    width: 100%;
    height: 211px;
    background: linear-gradient(0deg, v-bind(backgroundColorMain) 54.69%, rgba(110, 54, 52, 0) 100%);
    content: '';
    pointer-events: none;

    @include mobile {
        height: 114px;
    }
}
.block-flyout-departments-mobile__title {
    z-index: 2;
    display: flex;
    align-items: center;
    column-gap: 5px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}

.block-flyout-departments-mobile__links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 30px;
    column-gap: 40px;
    row-gap: 20px;
}

.block-flyout-departments-mobile__link-wrapper {
    display: flex;
    width: fit-content;
    align-items: center;
    column-gap: 12px;
}

.block-flyout-departments-mobile__link {
    position: relative;
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);

    &.atom-link {
        font-family: var(--f-family--thin);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);
    }

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

.block-flyout-departments-mobile__link-image {
    width: 23px;
    height: 22px;
}
.block-flyout-departments-mobile__link-image-wrapper {
    width: 23px;
}
</style>
