<template>
  <div class="block-nav-departments">
    <div class="block-nav-departments__departments">
      <div
        v-for="department in data.content[0].departments"
        :key="department._uid"
        class="block-nav-departments__department"
        @click="setFlyoutData(department)"
        @keypress="setFlyoutData(department)"
      >
        <AtomImage
          v-if="department.image?.filename"
          class="block-nav-departments__department-image"
          :image="department.image"
          provider="storyblok"
          width="250px"
          height="250px"
        />
        <div class="block-nav-departments__department-label">
          {{ department.label }}
          <IonIcon icon-name="chevron-right" />
        </div>
      </div>
    </div>
    <div class="block-nav-departments__links">
      <AtomLink
        v-for="link in filteredLinks"
        :key="link._uid"
        :link="link.link.cached_url"
        :text="link.label"
      />
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

/*
  Get the current locale and language
*/
const { locale: currentLocale, defaultLocale } = useI18n();

const flyoutStore = useFlyoutStore();

const setFlyoutData = (data) => {
    if (data?.content_main && data?.content_main.length > 0) {
        flyoutStore.setFlyoutDataSide(data?.content_side[0]);
        flyoutStore.setFlyoutDataMain(data?.content_main[0], data?.content_side[0]);
        flyoutStore.setFlyoutDataContact(
            data?.contact_image,
            data?.contact_title,
            data?.contact_text,
            data?.contact_link,
            data?.contact_link_label,
        );
        flyoutStore.setFlyoutColor(data?.color);
        flyoutStore.setFlyoutDepartmentName(data?.label);
    } else if (data?.content_main
    && data?.content_main.length === 0
    && data?.direct_link?.cached_url) {
        navigateTo(currentLocale.value !== defaultLocale ? data?.direct_link.cached_url : `/${data?.direct_link.cached_url}`);
        flyoutStore.setFlyoutState(false);
    }
};

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.getIsAuthenticated);
const filteredLinks = computed(
    // filter lins if isAuth is true and no user is logged in
    () => props.data.content[0].links.filter((link) => !link.isAuth || isAuthenticated.value),
);
</script>

<style lang="scss">
.block-nav-departments {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 60px;
}

.block-nav-departments__departments {
    display: flex;
    column-gap: 40px;
}

.block-nav-departments__department {
    @include fluid('width', 20px, 260px);

    display: flex;
    flex-direction: column;
    cursor: pointer;
    row-gap: 20px;

    .ion-icon {
        transition: all 0.3s ease;
    }

    &:hover {
        .ion-icon {
            transform: translate(5px, 0)
        }
    }

}

.block-nav-departments__department-image {
    max-width: 260px;
    max-height: 260px;

}

.block-nav-departments__department-label {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}

.block-nav-departments__links {
    display: flex;
    width: fit-content;
    flex-direction: column;
    row-gap: 20px;

    .atom-link__text {
        font-family: var(--f-family--thin);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);

        &:after {
            position: absolute;
            bottom: -6px;
            display: block;
            width: 32px;
            height: 2px;
            border: 50%;
            background-color: var(--c-white);
            content: '';
            opacity: 0;
            transition: opacity 0.3s ease;
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
}
</style>
