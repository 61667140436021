<template>
  <div class="block-flyout-departments">
    <UtilRouteLink
      v-if="flyoutDataSide?.link?.cached_url"
      :link="flyoutDataSide.link.cached_url"
      class="block-flyout-departments__title"
    >
      <div>
        {{ data.title }}
      </div>
      <IonIcon class="block-flyout-departments__title-icon" icon-name="chevron-right" />
    </UtilRouteLink>
    <div v-else>
      {{ data.title }}
    </div>

    <div class="block-flyout-departments__links">
      <div
        v-for="link in data.links"
        :key="link._uid"
      >
        <div
          v-if="link.sub_links.length === 0"
          class="block-flyout-departments__link-wrapper"
        >
          <AtomLink
            class="block-flyout-departments__link"
            :link="link.link.cached_url"
            :text="link.label"
          />
          <div v-if="link.label_image?.filename">
            <AtomImage
              :image="link.label_image"
              class="block-flyout-departments__link-image"
              provider="storyblok"
            />
          </div>
        </div>
        <div
          v-else-if="link.sub_links.length > 0"
          class="block-flyout-departments__link-wrapper"
          @click="setFlyoutData(link)"
          @keypress="setFlyoutData(link)"
        >
          <p class="block-flyout-departments__link">
            {{ link.label }}
          </p>
          <div v-if="link.label_image?.filename">
            <AtomImage
              :image="link.label_image"
              class="block-flyout-departments__link-image"
              provider="storyblok"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="block-flyout-departments__navigation">
      <div class="block-flyout-departments__navigation-top">
        <p>{{ navName }}</p>
        <p class="block-flyout-departments__navigation-pipe" />
        <p>{{ departmentName }}</p>
      </div>
      <div
        class="block-flyout-departments__navigation-back"
        @click="setPreviousFlyoutData()"
        @keypress="setPreviousFlyoutData()"
      >
        <IonIcon icon-name="chevron-left" />
        <p>{{ useStoryblokTranslation('general', 'back') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const flyoutStore = useFlyoutStore();
const departmentName = computed(() => flyoutStore.getFlyoutDepartmentName);
const navName = computed(() => flyoutStore.getFlyoutNavName);
const flyoutDataSide = computed(() => flyoutStore.getFlyoutDataSide);

const setFlyoutData = (data) => {
    // prepare data for flyoutstore
    const flyoutData = {
        title: data.label,
        links: data.sub_links,
        content_side: data?.content_side[0],
    };

    // set flyout data main
    flyoutStore.setFlyoutDataMain(flyoutData);

    // set flyout data side
    if (data?.content_side?.length > 0) {
        flyoutStore.setFlyoutDataSide(data?.content_side[0]);
    }
};

const setPreviousFlyoutData = () => {
    flyoutStore.setFlyoutDataMain();
};
</script>

<style lang="scss" scoped>
.block-flyout-departments {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
.block-flyout-departments__title {
    display: flex;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h3);
    gap: 10px;
    line-height: var(--l-height--h3);

    .block-flyout-departments__title-icon {
        transition: all 0.3s ease;
    }

    &:hover {
        .block-flyout-departments__title-icon {
            transform: translate(5px, 0);
        }
    }

    div {
        padding-top: 3px;
    }
}

.block-flyout-departments__links {
    display: flex;
    max-height: 260px;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 100px;
    column-gap: 40px;
    row-gap: 20px;

    @media screen and (max-width: 1650px) {
        padding-left: 20px;
    }
}

.block-flyout-departments__link-wrapper {
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.block-flyout-departments__link {
    position: relative;
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);

    &.atom-link {
        font-family: var(--f-family--thin);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);

        @media screen and (max-width: 1650px) {
            font-family: var(--f-family--thin);
            font-size: var(--f-size--richtext);
            line-height: var(--l-height--richtext);
        }
    }

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }

    @media screen and (max-width: 1650px) {
        font-family: var(--f-family--thin);
        font-size: var(--f-size--richtext);
        line-height: var(--l-height--richtext);
    }
}
.block-flyout-departments__link-image {
    width: 23px;
    height: 22px;
}

.block-flyout-departments__navigation {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.block-flyout-departments__navigation-top {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
}

.block-flyout-departments__navigation-pipe {
    width: 1px;
    height: 29px;
    background: var(--c-white);
}

.block-flyout-departments__navigation-back {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);

    .ion-icon {
        transition: all 0.3s ease;
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0)
        }
    }
}
</style>
