<template>
  <nav
    ref="wrapperRef"
    class="block-navigation-language"
    :class="{ 'is-open': isOpen }"
  >
    <div
      class="block-navigation-language__active-lang"
      @click="toggleOpen()"
      @keypress="toggleOpen()"
    >
      <p>{{ locale }}</p>
      <IonIcon
        v-if="mappedLocales.length > 1"
        icon-name="chevron-down"
        class="block-navigation-language__icon"
      />
    </div>
    <ul class="block-navigation-language__list">
      <li
        v-for="lang in mappedLocales || locales"
        :key="lang.code"
        class="block-navigation-language__list-item"
        :class="{ 'is-active': locale === lang.code }"
      >
        <div
          v-if="isStorybook"
          class="block-navigation-language__list-item-link"
        >
          {{ lang.code }}
        </div>

        <NuxtLink
          v-else
          class="block-navigation-language__list-item-link"
          :to="getLocalePath(lang.code)"
          @click="flyoutStore.setFlyoutState(false)"
          @keypress="flyoutStore.setFlyoutState(false)"
        >
          {{ lang.name }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script setup>
import { onClickOutside } from '@vueuse/core';
/*
  Internationalization
*/
/*
  This is a workaround for Storybook until this issue is closed:
  https://github.com/storybook-vue/nuxt/issues/7
*/
const isStorybook = process.env.IS_STORYBOOK;

/*
  Locales
*/
/*
  This is a workaround for using i18n in storybook until the rc-30 release of storybook-vue/nuxt
*/
const { locale, locales } = isStorybook ? {
    locale: 'de',
    locales: [
        {
            code: 'de',
            iso: 'de-CH',
            name: 'De',
        },
        {
            code: 'it',
            iso: 'it-CH',
            name: 'It',
        },
        {
            code: 'fr',
            iso: 'fr-CH',
            name: 'Fr',
        },
        {
            code: 'en',
            iso: 'en-CH',
            name: 'En',
        },

    ],
} : useI18n();

const slugsStore = useSlugsStore();
const flyoutStore = useFlyoutStore();
const getLocalePath = (lang) => {
    const translatedSlug = slugsStore.switchLocalePath(lang);
    return translatedSlug;
};

const isOpen = ref(false);
const toggleOpen = () => {
    isOpen.value = !isOpen.value;
};

watch(locale, () => {
    isOpen.value = false;
});

const wrapperRef = ref(null);

/* Close dropdown on click outside */
onClickOutside(wrapperRef, () => {
    isOpen.value = false;
});

/*
  Adjustable language navigation
*/
const storyStore = useStoryStore();
const injectedLocales = computed(() => storyStore.getLocales);
const mappedLocales = ref([]);

const filterLocales = () => {
    mappedLocales.value = [];

    injectedLocales.value.forEach((item) => {
        locales.value.forEach((lang) => {
            if (lang.code === item) {
                mappedLocales.value.push(lang);
            }
        });
    });
};

filterLocales();

watch(injectedLocales, () => {
    filterLocales();
});

const colorTopLevel = inject('colorSchema');
const color1 = computed(() => `var(--c-${colorTopLevel.value}-1)`);
</script>

<style lang="scss" scoped>
.block-navigation-language {
    position: relative;
    padding-top: 26px;
    color: v-bind(color1);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
    text-transform: uppercase;

    @include tablet {
        padding: unset;
    }
}

.block-navigation-language__active-lang {
    display: flex;
    padding: 0 10px 5px 10px;
    column-gap: 5px;
    cursor: pointer;

    @include tablet {
        padding: unset;
    }
}

.block-navigation-language__icon {
    transition: transform 0.6s $EASE_IN_OUT--BACK;

    .is-open & {
        transform: rotate(180deg);
    }
}

.block-navigation-language__list {
    @include z-index('langNav');

    position: absolute;
    display: none;
    width: 100%;
    flex-direction: column;
    background: var(--c-white);

    .is-open & {
        display: flex;
    }
}

.block-navigation-language__list-item {
    position: relative;
    display: flex;
    padding: 5px 10px;
    color: var(--c-secondary);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--richtext);
    text-transform: uppercase;

    &:last-child {
        &:after {
            display: none;
        }
    }

    &:hover {
        text-decoration: underline;
        text-underline-offset: 5px;
    }

    &.is-active {
        display: none;
    }

    @include tablet {
        padding: 5px 0;
    }
}

.block-navigation-language__list-item-link {
    color: v-bind(color1);
    cursor: pointer;
}
</style>
