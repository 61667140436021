<template>
  <div class="block-flyout-side__content-side">
    <AtomImage
      v-if="data.image?.filename"
      class="block-flyout-side__content-side-image"
      :image="data.image"
      provider="storyblok"
      height="170px"
      width="170px"
    />
    <div
      v-if="data.title"
      class="block-flyout-side__content-side-title"
    >
      {{ data.title }}
    </div>
    <div class="block-flyout-side__content-side-text">
      {{ data.text }}
    </div>
    <UtilRouteLink
      v-if="data.link?.id || data.link?.url"
      :link="data.link.cached_url"
    >
      <AtomButton
        :text="data.titleBtn ? data.titleBtn : useStoryblokTranslation('general', 'to_overview')"
        variant="inverted"
        :color-schema="currentColor !== null ? currentColor : 'petrol'"
        html-tag="div"
      />
    </UtilRouteLink>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        required: true,
        default: () => {},
    },
});

const flyoutStore = useFlyoutStore();
const currentColor = computed(() => flyoutStore.getFlyoutColor);
</script>

<style lang="scss">
.block-flyout-side__content-side {
    @include fluid('padding', 20px, 60px);

    display: flex;
    width: 440px;
    min-width: 340px;
    flex-direction: column;
    justify-content: center;
    background: var(--c-petrol-4);
}

.block-flyout-side__content-side-image {
    width: 153px;
    margin-top: 21px;
    margin-bottom: 40px;
    aspect-ratio: 1/1;
}

.block-flyout-side__content-side-title {
    margin-bottom: 10px;
    color: var(--c-white);
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h4);
    hyphens: auto;
    line-height: var(--l-height--h4);
}

.block-flyout-side__content-side-text {
    margin-bottom: 20px;
    color: var(--c-white);
    font-family: var(--f-family--thin);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
}
</style>
