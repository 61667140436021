<template>
  <div class="block-flyout-regions">
    <UtilRouteLink
      v-if="flyoutDataSide?.link?.cached_url"
      :link="flyoutDataSide.link.cached_url"
      class="block-flyout-regions__title"
    >
      <div>
        {{ data.title }}
      </div>
      <IonIcon class="block-flyout-regions__title-icon" icon-name="chevron-right" />
    </UtilRouteLink>
    <div v-else class="block-flyout-regions__title">
      {{ data.title }}
    </div>
    <div class="block-flyout-regions__links">
      <div
        v-for="link in data.links"
        :key="link._uid"
      >
        <AtomLink
          v-if="isArrayEmpty(link.sub_links) && !link.label_image?.filename"
          class="block-flyout-regions__link"
          :link="link.link.cached_url"
          :text="link.label"
          @click="link.country ? setFilter(link.country) : null"
          @keypress="link.country ? setFilter(link.country) : null"
        />
        <UtilRouteLink
          v-else-if="isArrayEmpty(link.sub_links)"
          class="block-flyout-regions__link"
          :link="link.link.cached_url"
          @click="link.country ? setFilter(link.country) : null"
          @keypress="link.country ? setFilter(link.country) : null"
        >
          <p>
            {{ link.label }}
          </p>
          <AtomImage
            v-if="link.label_image?.filename"
            :image="link.label_image"
            class="block-flyout-regions__link-image"
            provider="storyblok"
          />
        </UtilRouteLink>
        <div
          v-else-if="isArrayNotEmpty(link.sub_links)"
          class="block-flyout-regions__link"
          @click="setFlyoutData(link)"
          @keypress="setFlyoutData(link)"
        >
          <p>
            {{ link.label }}
          </p>
          <AtomImage
            v-if="link.label_image?.filename"
            :image="link.label_image"
            class="block-flyout-regions__link-image"
            provider="storyblok"
          />
        </div>
      </div>
      <UtilRouteLink
        v-if="data?.link?.id || data?.link?.url"
        :link="data.link.cached_url"
      >
        <AtomButton
          :text="data.link_label"
          :color-schema="buttonColor"
          variant="inverted"
          html-tag="div"
        />
      </UtilRouteLink>
    </div>
    <div class="block-flyout-regions__navigation">
      <div class="block-flyout-regions__navigation-top">
        <p>{{ navName }}</p>
        <p class="block-flyout-regions__navigation-pipe" />
        <p>{{ regionName }}</p>
        <p
          v-if="regionCountry !== null"
          class="block-flyout-regions__navigation-pipe"
        />
        <p v-if="regionCountry">
          {{ regionCountry }}
        </p>
      </div>
      <div
        class="block-flyout-regions__navigation-back"
        @click="setPreviousFlyoutData()"
        @keypress="setPreviousFlyoutData()"
      >
        <IonIcon icon-name="chevron-left" />
        <p>{{ useStoryblokTranslation('general', 'back') }}</p>
      </div>
    </div>
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const flyoutStore = useFlyoutStore();
const flyoutDataSide = computed(() => flyoutStore.getFlyoutDataSide);
const regionName = computed(() => flyoutStore.getFlyoutRegionName);
const regionCountry = computed(() => flyoutStore.getFlyoutRegionCountry);
const navName = computed(() => flyoutStore.getFlyoutNavName);
const buttonColor = computed(() => flyoutStore.getFlyoutColor);

const setFlyoutData = (data) => {
    // prepare data for flyoutstore
    const flyoutData = {
        title: data.label,
        links: data.sub_links,
        content_side: data?.content_side[0],
    };

    // set flyout data main
    flyoutStore.setFlyoutDataMain(flyoutData);
    flyoutStore.setFlyoutRegionCountry(data.label);

    // set flyout data side
    if (data?.content_side?.length > 0) {
        flyoutStore.setFlyoutDataSide(data?.content_side[0]);
    }
};

const setPreviousFlyoutData = () => {
    flyoutStore.setFlyoutDataMain();
};

/*
  Set filter if country is selected
*/
const filterStore = useFilterStore();

const setFilter = (country) => {
    filterStore.setPrefilteredCountry(country);
};
</script>

<style lang="scss" scoped>
.block-flyout-regions {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}
.block-flyout-regions__title {
    display: flex;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--h4);
    gap: 10px;
    line-height: var(--l-height--h4);
}
.block-flyout-regions__links {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding-left: 100px;
    row-gap: 20px;
}

.block-flyout-regions__link {
    position: relative;
    display: flex;
    column-gap: 20px;
    cursor: pointer;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

.block-flyout-regions__link-image {
    width: 32px;
    height: 22px;
}

.block-flyout-regions__navigation {
    position: absolute;
    bottom: 0;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.block-flyout-regions__navigation-top {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--thin);
    font-size: var(--f-size--tag);
    white-space: nowrap;
}

.block-flyout-regions__navigation-pipe {
    width: 1px;
    height: 29px;
    background: var(--c-white);
}

.block-flyout-regions__navigation-back {
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);

    .ion-icon {
        transition: all 0.3s ease;
    }

    &:hover {
        .ion-icon {
            transform: translate(-5px, 0)
        }
    }
}
</style>
