<template>
  <div class="block-nav-departments-mobile">
    <Transition name="fade-in">
      <AtomLink
        v-if="mainLink?.link?.id || dataSide?.link?.id"
        :text="mainLink.title ? mainLink.title : dataSide.title"
        :link="mainLink.link ? mainLink.link.cached_url : dataSide.link.cached_url"
        icon="chevron-right"
        class="block-nav-departments-mobile__main-link"
      />
    </Transition>
    <div class="block-nav-departments-mobile__departments">
      <div
        v-for="department in data.content[0].departments"
        :key="department._uid"
        class="block-nav-departments-mobile__department"
        @click="setFlyoutData(department)"
        @keypress="setFlyoutData(department)"
      >
        <AtomImage
          v-if="department.image?.filename"
          class="block-nav-departments-mobile__department-image"
          :image="department.image"
          provider="storyblok"
        />
        <div class="block-nav-departments-mobile__department-label">
          {{ department.label }}
          <IonIcon icon-name="chevron-right" />
        </div>
      </div>
    </div>
    <div class="block-nav-departments-mobile__links">
      <div
        v-for="link in filteredLinks"
        :key="link._uid"
      >
        <AtomLink
          v-if="!link.content_main"
          :link="link.link.cached_url"
          :text="link.label"
        />
        <div
          v-else-if="link.content_main.length > 0"
          class="block-nav-departments-mobile__link"
          @click="setFlyoutData(link)"
          @keypress="setFlyoutData(link)"
        >
          {{ link.label }}
        </div>
      </div>
    </div>
    <div class="block-nav-departments-mobile__gradient" />
    <div
      class="block-flyout-departments-mobile__navigation-back"
      @click="setPreviousFlyoutData()"
      @keypress="setPreviousFlyoutData()"
    >
      <IonIcon icon-name="chevron-left" />
      <p>{{ useStoryblokTranslation('general', 'back') }}</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    dataSide: {
        type: String,
        default: '',
    },
});

const { locale: currentLocale, defaultLocale } = useI18n();
const flyoutStore = useFlyoutStore();
const flyoutColor = computed(() => flyoutStore.getFlyoutColor);
const backgroundColorMain = ref('var(--c-petrol-2)');
const backgroundColorSide = ref('var(--c-petrol-4)');
const mainLink = ref({});

watch(flyoutColor, () => {
    if (flyoutColor.value) {
        backgroundColorMain.value = `var(--c-${flyoutColor.value}-2)`;
        backgroundColorSide.value = `var(--c-${flyoutColor.value}-4)`;
    } else if (!flyoutColor.value) {
        backgroundColorMain.value = 'var(--c-petrol-2)';
        backgroundColorSide.value = 'var(--c-petrol-4)';
    }
});

const setFlyoutData = (data) => {
    if (data?.content_main && data?.content_main.length > 0) {
        flyoutStore.setFlyoutDataSide(data?.content_side[0]);
        flyoutStore.setFlyoutDataMain(data?.content_main[0], data?.content_side[0]);
        flyoutStore.setFlyoutDataContact(
            data?.contact_image,
            data?.contact_title,
            data?.contact_text,
            data?.contact_link,
            data?.contact_link_label,
        );
        flyoutStore.setFlyoutColor(data?.color);
        flyoutStore.setFlyoutDepartmentName(data?.label);
    } else if (data?.content_main
    && data?.content_main.length === 0
    && data?.direct_link?.cached_url) {
        navigateTo(currentLocale.value !== defaultLocale ? data?.direct_link.cached_url : `/${data?.direct_link.cached_url}`);
        flyoutStore.setFlyoutState(false);
    }
};

const setPreviousFlyoutData = () => {
    flyoutStore.resetFlyoutData();
};

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.getIsAuthenticated);
const filteredLinks = computed(
    // filter lins if isAuth is true and no user is logged in
    () => props.data.content[0].links.filter((link) => !link.isAuth || isAuthenticated.value),
);
</script>

<style lang="scss">
.block-nav-departments-mobile {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: var(--c-white);
    column-gap: 60px;
}

.block-nav-departments-mobile__main-link {
    display: flex;
    width: fit-content;
    align-items: center;
    margin-bottom: 20px;

    .atom-link__text {
        font-family: var(--f-family--regular);
        font-size: var(--f-size--h3);
        line-height: var(--l-height--h3);
    }
}

.block-nav-departments-mobile__departments {
    display: grid;
    padding: 0 30px;
    margin-bottom: 40px;
    column-gap: 40px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 40px;
}

.block-nav-departments-mobile__department {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    grid-column: span 1;
    row-gap: 10px;

    .ion-icon {
        transition: all 0.3s ease;
    }

    &:hover {
        .ion-icon {
            transform: translate(5px, 0)
        }
    }
}

.block-nav-departments-mobile__department-label {
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--bold);
    font-size: var(--f-size--h4);
    line-height: var(--l-height--h4);
    word-break: break-all;
}

.block-nav-departments-mobile__gradient {
    position: absolute;
    z-index: 1;
    bottom: 114px;
    width: 100%;
    height: 211px;
    background: linear-gradient(0deg, v-bind(backgroundColorMain) 54.69%, rgba(110, 54, 52, 0) 100%);
    content: '';
    pointer-events: none;

    @include mobile {
        height: 114px;
    }
}

.block-nav-departments-mobile__links {
    display: flex;
    width: fit-content;
    flex-direction: column;
    row-gap: 20px;

    .atom-link__text, .block-nav-departments-mobile__link {
        position: relative;
        cursor: pointer;
        font-family: var(--f-family--thin);
        font-size: var(--f-size--h4);
        line-height: var(--l-height--h4);

        &:after {
            position: absolute;
            bottom: -6px;
            display: block;
            width: 32px;
            height: 2px;
            border: 50%;
            background-color: var(--c-white);
            content: '';
            opacity: 0;
            transition: opacity 0.3s ease;
        }

        &:hover {
            &:after {
                opacity: 1;
            }
        }
    }
}

.block-flyout-departments-mobile__navigation-back {
    position: absolute;
    z-index: 1;
    bottom: 142px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
</style>
