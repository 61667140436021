<template>
  <div class="block-flyout-contact__content-main-contact">
    <AtomImage
      v-if="data.contact_image?.filename"
      class="block-flyout-contact__content-main-contact-image"
      :image="data.contact_image"
      provider="storyblok"
      width="430px"
      height="500px"
    />
    <AtomRichtext
      v-if="data.contact_text"
      class="block-flyout-contact__content-main-contact-link-text"
      :html="storyblokApi.richTextResolver.render(data.contact_text)"
    />
    <AtomLink
      v-if="data.contact_link?.id || data.contact_link?.url"
      ref="refLink"
      class="block-flyout-contact__content-main-contact-link"
      :link="data.contact_link.cached_url"
      :text="data.contact_link_label"
      icon="chevron-right"
      @click="emitData"
      @keypress="emitData"
    />
  </div>
</template>

<script setup>
defineProps({
    data: {
        type: Object,
        required: true,
        default: () => {},
    },
});

const refLink = ref(null);
const linkWidth = ref(null);

onMounted(() => {
    linkWidth.value = refLink.value?.offsetWidth ? `${refLink.value.offsetWidth + 50}px` : 'auto';
});

const storyblokApi = useStoryblokApi();
const emit = defineEmits(['close-flyout']);
const emitData = () => emit('close-flyout', true);
</script>

<style lang="scss">
.block-flyout-contact__content-main-contact {
    position: absolute;
    right: 80px;
    display: flex;
    min-width: v-bind(linkWidth);
    max-width: 360px;
    flex-direction: column;
    align-self: flex-end;
    row-gap: 20px;

    @media screen and (max-width: 1650px) {
        max-width: 250px;
    }

    @include tablet {
        position: relative;
        right: unset;
        width: 100%;
        min-width: unset;
        max-width: unset;
        padding: 30px;
        margin-bottom: 40px;
        color: var(--c-white);
    }
}

.block-flyout-contact__content-main-contact-image,
.block-flyout-contact__content-main-contact-link-text {
    @include tablet {
        display: none;
    }
}

</style>
