<template>
  <div class="block-nav-links-mobile__content-main-links-inner">
    <Transition name="fade-in">
      <AtomLink
        v-if="mainLink?.link?.id || dataSide?.link?.id"
        :text="mainLink.title ? mainLink.title : dataSide.title"
        :link="mainLink.link ? mainLink.link.cached_url : dataSide.link.cached_url"
        icon="chevron-right"
        class="block-nav-links-mobile__main-link"
        @click="resetData"
        @keypress="resetData"
      />
    </Transition>
    <div
      v-if="data?.content[0]?.links.length > 0 && subLinks.length == 0"
      class="block-nav-links-mobile__content-main-links"
    >
      <div
        v-for="link, index in filteredLinks"
        :key="link._uid"
        class="block-nav-links-mobile__content-main-links-link"
        :class="{ 'is-active': currentDataIndex === index }"
      >
        <AtomLink
          v-if="link.sub_links?.length === 0"
          :text="link.label"
          :link="link.link.cached_url"
          :is-auth="link.isAuth"
          icon="none"
          @click="resetData"
          @keypress="resetData"
        />

        <div
          v-else
          @click="currentDataIndex !== index ? setSubLinkData(link, index) : null"
          @keypress="currentDataIndex !== index ? setSubLinkData(link, index) : null"
        >
          {{ link.label }}
        </div>
      </div>
    </div>
    <div
      v-if="subLinks.length !== 0"
      class="block-nav-links-mobile__content-main-sub-links"
    >
      <div
        v-for="link in subLinks"
        :key="link._uid"
      >
        <AtomLink
          v-if="link.component === 'NavItem'"
          :text="link.label"
          :link="link.link.cached_url"
          icon="none"
          class="block-nav-links__content-main-sub-links-link"
          @click="emitData"
          @keypress="emitData"
        />

        <a
          v-else-if="link.component === 'NavItemDownload'"
          :href="link.file.filename"
          target="_blank"
          rel="noopener noreferrer"
          class="block-nav-links__content-main-sub-links-download"
        >
          {{ link.label }}
          <IonIcon icon-name="arrow-download" />
        </a>
      </div>
    </div>
    <div
      class="block-nav-links-mobile__navigation-back"
      @click="setPreviousFlyoutData()"
      @keypress="setPreviousFlyoutData()"
    >
      <IonIcon icon-name="chevron-left" />
      <p>{{ useStoryblokTranslation('general', 'back') }}</p>
    </div>
  </div>
</template>

<script setup>
const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },

    dataSide: {
        type: String,
        default: '',
    },
});

const mainLink = ref({});
const subLinks = ref([]);
const currentDataIndex = ref(null);
const flyoutStore = useFlyoutStore();

const setSubLinkData = (link, index) => {
    mainLink.value = {
        title: link.label,
        link: link.link.cached_url,
    };

    currentDataIndex.value = index;
    subLinks.value = link.sub_links;
};

const resetData = () => {
    currentDataIndex.value = null;
    mainLink.value = {};
    subLinks.value = [];
};

const setPreviousFlyoutData = () => {
    if (subLinks.value.length !== 0) {
        resetData();
    } else {
        flyoutStore.resetFlyoutData();
    }
};

const authStore = useAuthStore();
const isAuthenticated = computed(() => authStore.getIsAuthenticated);
const filteredLinks = computed(
    // filter lins if isAuth is true and no user is logged in
    () => props.data.content[0].links.filter((link) => !link.isAuth || isAuthenticated.value),
);
</script>

<style lang="scss">
.block-nav-links-mobile__content-main-links-inner {
    display: flex;
    flex-direction: column;
    padding: 32px 0 28px 25px;
    color: var(--c-white);
    row-gap: 10px;

    @include tablet {
        padding: 0;
    }
}

.block-nav-links-mobile__content-main-links {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    row-gap: 20px;
}

.block-nav-links-mobile__content-main-links-link {
    position: relative;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);

    div {
        cursor: pointer;
    }

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .ion-icon {
        transition: transform 0.3s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }

        .ion-icon {
            transform: translate(10px, 0);
        }
    }

    &.is-active {
        &:after {
            opacity: 1;
        }

        .ion-icon {
            transform: translate(10px, 0);
        }
    }
}

.block-nav-links-mobile__content-main-sub-links {
    display: flex;
    flex-direction: column;
    padding-left: 30px;
    row-gap: 15px;
}

.block-nav-links-mobile__content-main-sub-links-link {
    position: relative;

    &:after {
        position: absolute;
        bottom: -6px;
        display: block;
        width: 32px;
        height: 2px;
        border: 50%;
        background-color: var(--c-white);
        content: '';
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover {
        &:after {
            opacity: 1;
        }
    }
}

.block-nav-links-mobile__navigation-back {
    position: absolute;
    bottom: 142px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    font-family: var(--f-family--regular);
    font-size: var(--f-size--richtext);
    line-height: var(--l-height--richtext);
}
</style>
